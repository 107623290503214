export default {
  Index: {
    addHazard: '添加危险品信息',
    ventilationSettings: '通风设置',
    humiditySetting: '湿度设置',
    freezerTemperature: '冻柜温度',
    addBox: '新增箱子',
    nameOfGoods: '货物品名',
    addTo: '添加',
    dangerousGoods: '危险品货物',
    type: 'IMO类型{val1},UN{val2}',
    type1: 'IMO类型{val1}',
    numberOfContainers: '集装箱数量',
    largeContainer: '集装箱大小及类型',
    pleaseAddDanger: '请添加危险品信息',
    numberOfPackages: '包装数量',
    pleaseSelectAPackage: '请选择包装类型',
    boxTypeDoesNotMatch: '箱型不符合要求',
  },
};
