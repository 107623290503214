export default {
  Index: {
    queryResults: '查询结果为空',
    shipSchedule: '船期查询',
    next: '下一个',
    date: '日期',
    pleaseSelectItems: '请选择目的地',
    destinationIs: '目的地是一个必填字段',
    destination1: '目的地',
    pleaseSelectStart: '请选择始发地',
    from: '始发地是一个必填字段',
    origin: '始发地',
    route: '航线',
    sortOrder: '排序方式',
    queryResults1: '查询结果',
    shortestTransportation: '最短运输时间',
    earliestArrival: '最早到达时间',
    theEarliestDeparture: '最早离港时间',
    whole: '全部',
    search: '搜索',
  },
  ScheduleCard: {
    booking: '订舱',
    chineseSymbols: '截港时间',
    bookingDeadline: '订舱截止时间',
    openingTime: '开港时间',
    day: '天',
    chineseSymbols1: '运输时间',
    chineseSymbols2: '船名/航次',
    expandDetails: '展开详情',
    stowDetails: '收起详情',
    arrival: '到港',
    chineseSymbols3: '直达',
    transit: '次中转',
    departure: '离港',
    destination: '目的地：',
    origin: '始发地：',
    route: '航线：',
    shipNameAndVoyage: '船名航次：',
    deadline: 'VGM截止时间',
  },
};
