export default {
  Request: {
    requestError: '请求错误 {val1}',
    demurrageCharge: '滞箱费确认异常',
    uploadDelay: '上传滞期费凭证异常',
    inquireAboutTheStagnantBox: '查询滞箱费异常',
    queryDemurrage: '查询滞期费异常',
    requestError1: '请求错误 {val1}: {val2}',
    gatewayTimeout: '网关超时。',
    serviceIsImpossible: '服务不可用，服务器暂时过载或维护。',
    badGateway: '网关错误。',
    serverSend: '服务器发生错误，请检查服务器。',
    whenCreatingA: '当创建一个对象时，发生一个验证错误。',
    fileSize: '文件大小超出了服务器端的限制大小。',
    requestedAssets: '请求的资源被永久删除，且不会再得到的。',
    requestedGrid: '请求的格式不可得。',
    requestMethod: '请求方法不被允许。',
    pleaseSend: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
    usersGet: '用户得到授权，但是访问是被禁止的。',
    userDoesNot: '用户没有权限（令牌、用户名、密码错误）。',
    pleaseSend1: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
    deleteData: '删除数据成功。',
    aRequest: '一个请求已经进入后台排队（异步任务）。',
    createOrRepair: '新建或修改数据成功。',
    serverAssembly: '服务器成功返回请求的数据。',
  },
};
