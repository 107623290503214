export default {
  Index: {
    pleaseEnterTheLocation: '请输入地址',
    address: '地址',
    pleaseEnterTheMail: '请输入邮编',
    postalCode: '邮编',
    pleaseEnterEmail: '请输入邮箱',
    pleaseInputElectricity: '请输入电话',
    pleaseEnterTheArea: '请输入区号',
    pleaseEnterACopy: '请输入联系人',
    pleaseEnterTheCity: '请输入城市',
    pleaseEnterMale: '请输入公司税号',
    pleaseEnterMale1: '请输入公司名称',
    newContact: '新增联系人',
    saveAndClose: '保存并关闭',
    newContact1: '新建联系人信息已存在，是否继续保存',
    cancel: '取消',
    editContact: '编辑联系人',
    encodingFormat: '编码格式不正确',
  },
};
