export default {
  Index: {
    signIn: '登录',
    fullLink: 'ABC全链路',
    zhiyuanService: '致远服务',
    logOut: '退出登录',
    changePassword: '修改密码',
    emptyBoxReturn: '空箱返还地查询',
    routeIntroduction: '航线介绍',
    cargoTracking: '货物跟踪',
    contactUs: '联系我们',
    companyIntroduction: '公司介绍',
    homePage: '首页',
    shipDynamics: '船舶动态查询',
  },
};
