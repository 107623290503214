export default {
  Index: {
    ifYouAreRight: '如果您对滞箱费无异议，请按确认该项费用完成预约。',
    ifYouAreRight1: '如果您对滞箱费有异议，可以和致远相关人员联系。',
    paymentRelated: '支付相关费并上传支付凭证，完成预约。',
    paymentPage: 'NLI 支付页面',
    ifYouAreRight2:
      '如果您对滞期费有异议，可以和致远相关人员联系。如果您对滞期费无异议，\r\n              请至',
    accordingToYour: '已按照您确认的拆箱完成日期计算出所产生的的滞箱费',
    accordingToYourExpectation:
      '按照您预约的送货日期将有滞期费产生请先支付滞期费并上传支付凭证',
  },
};
