import * as SentryUtil from '@idx/sentry-util/es/browser';
import { history } from 'umi';
// src/global.ts
if (process.env.NODE_ENV !== 'development') {
  const packageJson = require('../package.json');
  const packageName = packageJson.name;
  SentryUtil.init({
    dsn: 'https://444e516a3c784559b8fc1b3b8a91138e@fem.900jit.com/40',
    environment: process.env.NODE_ENV,
    release: GIT_COMMIT_SHA,
    packageName,
    getUserInfo: () => {
      //获取用户信息，不支持异步方法，如果不想提交用户信息可以不传
      //gm1、gm2项目可以配置dzgAppCode，会注入默认的getUserInfo方法，具体参数请参考附录api
    },
    // 如果是umi3的项目请使用方法传递history，避免出现umi3的双history bug导致的事务采集错误
    history: () => history,
    // history
  });
}
