// import { loading } from '@dzg/common-utils';
import commonUtils from '@/utils/dynamic/common-utils';
import { request } from 'umi';

/**
 * loading 请求包裹
 * @param {string} url 接口地址
 * @param {*} options 请求配置
 * @return {*}
 */
export const loadingRequest: typeof request = async (url, options) => {
  let { loading } = await commonUtils;
  loading.start();
  return request(url, options).finally(() => {
    loading.destroy();
  });
};
