export default {
  CargoInfo: {
    remarks: '备注',
    enclosure: '附件',
    no: '否',
    yes: '是',
    whether: '是否soc',
    typeOfGoods: '货物类型',
    nameOfGoods: '货物名称',
    transportationTerms: '运输条款',
    valueAddedServices: '增值服务',
    cargoInformation: '货物信息',
    fullLink: 'ABC全链路',
    category: 'IMO类别',
    dangerousGoods: '危险品类别',
    totalGrossWeight: '总毛重',
    grossWeightPerCase: '单箱毛重',
    boxTypeAndQuantity: '箱型箱量',
    otherInformation: '其他信息',
    placeOfPayment: '付费地',
    freightTerms: '运费条款 PAYABALE AT 3RD PLACE',
    agreementNo: '协议号',
    rateInformation: '费率信息',
    numberOfPackages: '包装数量',
    stateName: '州名',
    city: '城市',
    businessType: '业务类型',
    valueAddedServices1: '增值服务',
  },
  ContactsInfo: {
    detailedAddress: '详细地址',
    dutyParagraph: '税号',
    telephone: '电话',
    contacts: '联系人',
    mailbox: '邮箱',
    city: '城市',
    country: '国家',
    corporateName: '公司名称',
    notifier: '通知人',
    consignee: '收货人',
    consignor: '发货人',
  },
  Detail: {
    bookingNo: '订舱号：',
    shipNameAndVoyage: '船名航次:',
    day: '天',
    cutOffTime: '截VGM时间',
    arrivalDeadline: '进港截止时间',
    bookingDeadline: '订舱截止时间',
    whenCuttingAndReplenishingMaterials: '截补料时间',
    openingTime: '开港时间',
  },
  DetailHeader: {
    bookingDetails: '订舱详情',
    returnOrder: '返回订单列表',
    consolidatedGeneralOrder: '合并总单: ',
    splitGeneralOrder: '拆分总单: ',
    masterOrder: '主单：',
  },
  Index: {
    complete: '完成',
    copyBooking: '复制订舱',
    modifyBooking: '修改订舱',
    bookingHasBeenCompleted: '订舱已过截止时间，请联系客服修改订舱',
    cancelBooking: '取消订舱',
    bookingConfirmed: '订舱已确认，请联系客服取消订舱',
    log: '记录日志',
    essentialInformation: '基本信息',
    cancelBooking1: '取消订舱成功',
    cancel2: '是否取消订舱？',
    information: 'SI信息',
    replenishmentOfBillOfLading: '提单补料',
  },
  Log: {
    time: '时间',
    name: '名称',
    record: '记录',
    event: '事件',
  },
  Success: {
    manualIs: '人工正在处理中，订舱确认信息将会尽快发送给您',
    congratulations: '恭喜您，订舱信息已提交成功',
  },
  TextExpand: {
    bookingRefusal: '订舱拒绝原因：',
  },
  DetailFooter: {
    cancel: '取消',
  },
};
