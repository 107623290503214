export default {
  Index: {
    chiefManager:
      '大掌柜，一站式数智化跨境物流软件服务提供商，大掌柜国际物流云计算平台运营服务商，中国国际物流SaaS软件提供商和产业互联网服务的先行者，为国际物流行业及其上下游企业提供基于云计算、大数据和移动互联网技术的智能化SaaS软件和线上物流资源交易综合服务（订舱、拖车、支付等），帮助企业建立自己的商务平台，实现业务的在线化、数字化、标准化、自动化和智能化。',
    wenraoIsA:
      '文鳐是一家数智化跨境物流履约平台。 聚焦中小商家跨境货运需求场景， 整合生态资源协同， 重点服务阿里巴巴跨境电商平台上的中小客户， 提供确定性的国际物流服务， 打造全球数字化国际物流生态系统， 四大核心合作伙伴能力整合，打造互联网顶尖履约能力',
    cooperativePartner: '合作伙伴',
  },
  SearchTrackSchedule: {
    next: '下一个',
    date: '日期',
    pleaseSelectItems: '请选择目的地',
    destinationIs: '目的地是一个必填字段',
    destination1: '目的地',
    pleaseSelectStart: '请选择始发地',
    from: '始发地是一个必填字段',
    origin: '始发地',
    shipSchedule: '船期查询',
    cargoTracking: '货物跟踪',
    pleaseEnterTheName: '请输入提单号/柜号',
    minimumInput: '至少输入6个字符',
  },
};
