export default {
  Config: {
    billOfLadingEntrustment: '提单委托已被取消',
    caseNoIsNotIn: '箱号不在提单下',
    cityDropDown: '城市下拉接口异常',
    customerIsNot: '客户不是发货人类型',
    billOfLadingTransportation: '提单运输类型不是ABC全链路，请联系客服',
    outOfTime: '超出ATA时间',
    billOfLadingNoWeight: '提单号重复新增',
    billOfLadingNo1: '提单号不一致',
    userNameIsNot: '用户名不能为空',
    passwordCannot: '密码不能为空',
  },
};
