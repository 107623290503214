export default {
  Index: {
    day: '日',
    six: '六',
    five: '五',
    four: '四',
    three: '三',
    two: '二',
    one: '一',
  },
};
