export default {
  Config: {
    toBeConfirmed: '待确认',
  },
  Index: {
    theBillOfLadingNumberIs: 'TFL提单号是由4位英文字母加上6位数字组成的',
    zhiyuanWholeChain: '致远ABC全链路陆运委托',
    chineseSymbols:
      'Selected {val1} records，Total{val2} containers，{val3} warehouse.',
    selectedItems: '已选择 {val1} 项，共{val2}个箱子，{val3}个仓库名称。',
    totalRecords: '共 {val1} 条记录 第 {val2} / {val3}页',
  },
};
