import I18N from '@/utils/I18N';
import { notification, message } from 'antd';
// import { isBrowser } from 'umi';
import { isBrowser } from '@umijs/utils/lib/ssr.js';
import getMessageWidthTraceIDPromise from '@/utils/dynamic/getMessageWidthTraceID';

interface ResponseResultType {
  result: any; // 返回结果
  success: boolean; // 请求是否成功
  errorCode?: number; // 错误码
  msg?: string; // 错误信息
}

const EnvHostMap = {
  dev: 'http://123.60.167.141:30118',
  test: 'http://123.60.167.141:31004',
  prod: 'https://www.transfarshipping.com',
};

const codeMessage: any = {
  200: I18N.Src.Request.serverAssembly,
  201: I18N.Src.Request.createOrRepair,
  202: I18N.Src.Request.aRequest,
  204: I18N.Src.Request.deleteData,
  400: I18N.Src.Request.pleaseSend1,
  401: I18N.Src.Request.userDoesNot,
  403: I18N.Src.Request.usersGet,
  404: I18N.Src.Request.pleaseSend,
  405: I18N.Src.Request.requestMethod,
  406: I18N.Src.Request.requestedGrid,
  410: I18N.Src.Request.requestedAssets,
  413: I18N.Src.Request.fileSize,
  422: I18N.Src.Request.whenCreatingA,
  500: I18N.Src.Request.serverSend,
  502: I18N.Src.Request.badGateway,
  503: I18N.Src.Request.serviceIsImpossible,
  504: I18N.Src.Request.gatewayTimeout,
};

const errorCodeNoMessage: number[] = [
  103001, 103002, 103004, 103003, 103009, 103010, 103017, 103018, 103011,
  103012, 103026, 103027, 103028, 103029, 103031, 103033,
];

const errorHandler = (error: { response: Response }) => {
  const { response } = error;
  if (response && response.status) {
    const errorText = codeMessage[response.status] || response.statusText;
    const { status, url } = response;
    if (isBrowser()) {
      if (Number(status) === 401) {
        // message.error(I18N.Locales.Zh_CN.loginPassed);
        localStorage.removeItem('userInfo');
        localStorage.removeItem('token');
        setTimeout(() => {
          window.location.replace(`/home?openLoginModal=true`);
        }, 2000);
      } else {
        notification.error({
          message: I18N.template(I18N.Src.Request.requestError1, {
            val1: status,
            val2: url,
          }),
          description: errorText,
        });
      }
    }
  }

  if (!response && isBrowser()) {
    const errorText = codeMessage[500];
    notification.error({
      message: I18N.__Src.Request.requestException,
      description: errorText,
    });
  }
};

const requestInterceptors = (url: string, options: any) => {
  // 需要登录才能访问的接口添加token
  if (url.includes('portalAuth')) {
    options.headers['Authorization'] = `Bearer ${localStorage.getItem(
      'token',
    )}`;
  }

  if (!isBrowser()) {
    options.headers['Cookie'] = global._origin_cookies;
  }

  // 服务端访问接口必须带有域名或ip
  return {
    url: `${isBrowser() ? '' : EnvHostMap[process.env.ENV || 'prod']}${url}`,
    options,
  };
};

const responseInterceptors = async (response: any) => {
  const contentType = response.headers.get('Content-Type');
  const traceId = response.headers.get('traceId');
  let getMessageWidthTraceID: any = await getMessageWidthTraceIDPromise;
  if (contentType && contentType.match(/application\/json/i)) {
    const content: ResponseResultType = await response.clone().json();
    const { success, errorCode = 0, msg = '' } = content;
    try {
      if (success !== undefined && !success) {
        throw new Error(
          msg ||
            I18N.template(I18N.Src.Request.requestError, { val1: errorCode }),
        );
      }
      if (errorCode !== 0 && success) {
        if (errorCodeNoMessage.includes(errorCode)) {
          return response;
        }
        isBrowser() && message.error(getMessageWidthTraceID(msg, traceId));
      }
    } catch (errMsg: any) {
      isBrowser() &&
        message.error(getMessageWidthTraceID(errMsg.message, traceId));
    }
  } else {
    if (response.status !== 200 && isBrowser()) {
      const errorText = codeMessage[response.status] || response.statusText;
      isBrowser() && message.error(getMessageWidthTraceID(errorText, traceId));
      return false;
    }
  }
  return response;
};

const request = {
  errorHandler,
  responseInterceptors: [responseInterceptors],
  requestInterceptors: [requestInterceptors],
  credentials: 'include', // 默认请求是否带上cookie
  errorConfig: {
    adaptor: (resData: any) => {
      return {
        ...resData,
        showType: 0,
      };
    },
  },
};

export default request;
