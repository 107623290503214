// import { isBrowser } from 'umi';

import { isBrowser } from '@umijs/utils/lib/ssr.js';

export function setCookie(name: string, value: string, days?: number) {
  if (!isBrowser()) {
    return;
  }
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

export function getCookie(name: string) {
  if (isBrowser()) {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
  } else {
    return global._cookies?.[name];
  }
}
