export default {
  DraftList: {
    bookingNo: '订舱号',
    creationTime: '创建时间',
    draftName: '草稿名称',
    draftBillOfLading: '提单草稿列表',
    delete: '删除',
    edit: '编辑',
    preview: '预览',
    lastRepair: '上一次修改',
  },
  Index: {
    cancel: '取消',
    signOut: '退出',
    clickBack: '单击“退出”将删除您为此输入的所有详细信息。',
    noAfterExit: '退出后不保存所有详细信息',
    pleaseEnter: '请输入Place of Delivery',
    pleaseEnter1: '请输入Port of Discharge',
    pleaseEnter2: '请输入Port of Loading',
    pleaseEnter3: '请输入Place of Receipt',
    submit: '提交',
    saveDraft: '保存草稿',
    cancel1: '取消',
    pleaseEnterGoods: '请输入货物描述',
    pleaseEnterTheMark: '请输入唛头',
    pleaseSelectTransportation: '请选择运费条款',
  },
  DraftNameModal: {
    draftName: '草稿名称',
    createDraft: '创建草稿名称',
  },
  Detail: {
    complete: '完成',
  },
};
