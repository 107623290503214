export default {
  Index: {
    totalRecords: '共 {val1} 条记录 第 {val2} / {val3}页',
    modificationFailed: '修改失败',
    confirmOrNot: '是否确认取消',
    cancelFailed: '取消失败',
    chineseSymbols: 'Selected {val1} records，Total{val2} containers',
    selectedItems: '已选择 {val1} 项，共{val2}个箱子',
    confirmOrNot1: '是否确认费用',
  },
};
