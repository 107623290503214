// import { isBrowser } from 'umi';

import { isBrowser } from '@umijs/utils/lib/ssr.js';

export default new Promise((resolve) => {
  if (isBrowser()) {
    import(
      /* webpackChunkName: "@dzg/common-utils" */ '@dzg/common-utils'
    ).then((utils) => {
      resolve(utils);
    });
  } else {
    resolve({});
  }
});
