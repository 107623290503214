export default {
  Index: {
    shipNameAndVoyage: '船名航次',
    portOfDestination: '目的港',
    portOfDeparture: '起运港',
    refuseToBookSpace: '拒绝订舱',
    confirm: '确认',
    submit: '提交',
    pleaseSelect: '请选择',
    orderStatus: '订单状态',
    dateOfSailing: '开船日期',
    agreementNo: '协议号',
    billOfLadingNo: '提单号',
    bookingNo: '订舱号',
    myOrder: '我的订单',
    bookingHasBeenTaken: '订舱已取消，请勿重复操作',
    modify: '修改',
    cancelBooking: '取消订舱不能再修改订舱',
    copy: '复制',
    bookingInformation: '订舱信息已复制，请先选择船期',
    sailingDate: '开航日期',
    freightTerms: '运费条款',
    shipAtPortOfDeparture: '起运港-船名航次-目的港',
    cargoTracking: '货物跟踪',
    orderStatus1: '订单状态',
    replenishmentOfBillOfLading: '提单补料',
    agency: '代办',
    dismantle: '拆',
    and: '并',
    draftBillOfLading: '提单草稿',
    billOfLadingPreview: '提单预览',
    needToBeDealtWith: '待办',
  },
};
