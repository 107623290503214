// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/root/front/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "exact": true,
    "redirect": "/home"
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layout' */'@/layout'), loading: LoadingComponent}),
    "layout": false,
    "routes": [
      {
        "path": "/home",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home' */'@/pages/home'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/about/company",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__about__company' */'@/pages/about/company'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/about/contact",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__about__contact' */'@/pages/about/contact'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/contact",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__contact' */'@/pages/contact'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/route",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__route' */'@/pages/route'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/route-detail/:id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__route__detail' */'@/pages/route/detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/attachment/:id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__attachment' */'@/pages/attachment'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/tracking",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__tracking' */'@/pages/tracking'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/empty-return-locations",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__empty-return-locations' */'@/pages/empty-return-locations'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/modify-password",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__modify-password' */'@/pages/modify-password'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/forget-password",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__forget-password' */'@/pages/forget-password'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/full-link",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__full-link' */'@/pages/full-link'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/full-link-list",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__full-link-list' */'@/pages/full-link-list'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/custom-appt",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__custom-appt' */'@/pages/custom-appt'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/custom-appt/deliveryDetail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__custom-appt__deliveryDetail' */'@/pages/custom-appt/deliveryDetail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/custom-appt/returnDetail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__custom-appt__returnDetail' */'@/pages/custom-appt/returnDetail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/custom-appt/containerTracking",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__custom-appt__containerTracking' */'@/pages/custom-appt/containerTracking'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/ship-dynamic",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ship-dynamic' */'@/pages/ship-dynamic'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/shipping-schedule",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipping-schedule' */'@/pages/shipping-schedule'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/booking",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__booking' */'@/pages/booking'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/booking/booking-list",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__booking__booking-list' */'@/pages/booking/booking-list'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/booking/booking-template",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__booking__booking-template' */'@/pages/booking/booking-template'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/booking/booking-template/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__booking__booking-template__templateDetail' */'@/pages/booking/booking-template/templateDetail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/booking/booking-frequently-contact",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__booking__booking-frequently-contact' */'@/pages/booking/booking-frequently-contact'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/booking/booking-detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__booking__booking-detail' */'@/pages/booking/booking-detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/booking/si-submission",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__booking__si-submission' */'@/pages/booking/si-submission'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/booking/si-submission-detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__booking__si-submission-detail' */'@/pages/booking/si-submission-detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/booking/si-submission-draftList",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__booking__draft-list' */'@/pages/booking/draft-list'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/news-list",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__news-list' */'@/pages/news-list'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/news-detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__news-detail' */'@/pages/news-detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/schedule",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__schedule' */'@/pages/schedule'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/shipInfo",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__shipInfo' */'@/pages/shipInfo'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
