export default {
  Index: {
    viewAll: '查看全部',
    putAwayAll: '收起全部',
    updateTime: '更新时间',
    lastVoyage: '上一航次',
    queryResults: '查询结果为预计船期会根据实际情况变化',
    search: '搜索',
    dockFacilities: 'Terminal(码头设施)',
    port: 'Port(港口)',
  },
};
