export default {
  Index: {
    saveTemplate: '保存模板',
    pleaseEnterTheMail: '请输入邮箱号，多邮箱用英文逗号隔开',
    iWouldLikeToOrder: '我希望订舱确认发送至',
    rateInformation: '费率信息',
    createBooking: '创建订舱模板',
    editBooking: '编辑订舱模板',
    templateCreation: '模板创建成功',
    templateEditing: '模板编辑成功',
    templateName: '模板名称已存在',
  },
};
