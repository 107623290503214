export default {
  Index: {
    bookingService: '订舱服务',
    signOut: '退出',
    clickBack: '单击“退出”将删除您为此订舱输入的所有详细信息。',
    noAfterExit: '退出后不保存所有详细信息',
    media: '媒体',
    customerAnnouncement: '客户通告',
    journalism: '新闻',
    mediaAndMore: '媒体和更新',
    shipSchedule: '船期查询',
  },
};
