export default {
  Validform: {
    mailboxFormat: '邮箱格式不正确',
    decimalDigitsNo: '小数位不能超过2位',
    telephoneFormat: '电话格式不正确',
    mailboxIsTooLong: '邮箱过长',
    notAllowed: '不允许中文填写',
    pleaseEnterTheNumber: '请输入数字',
    pleaseFollowTheInstructions: '请按照提示正确填写',
  },
  Const: {
    cancel: 'SI取消',
    mediumProcessing: 'SI处理中/等待',
    refuse: 'SI拒绝',
    confirm: 'SI确认',
    submit: 'SI提交',
    cancellationOfBooking: '订舱取消',
    bookingWaiting: '订舱等待',
    bookingChange: '订舱更改请求',
    bookingRefusal: '订舱拒绝',
    bookingConfirmation: '订舱确认',
    bookingSubmission: '订舱提交',
  },
};
