import common from './common';
import { default as $__Src__Pages__ShipInfo } from './__Src__Pages__ShipInfo';
import { default as $__Src__Layout__Components__Footer } from './__Src__Layout__Components__Footer';
import { default as $__Src__Pages__Tracking } from './__Src__Pages__Tracking';
import { default as $__Src__Pages__Schedule } from './__Src__Pages__Schedule';
import { default as $__Src__Pages__News_detail } from './__Src__Pages__News_detail';
import { default as $__Src__Pages__News_list } from './__Src__Pages__News_list';
import { default as $__Src__Pages__Home } from './__Src__Pages__Home';
import { default as $__Src__Components__SiCtnInfo } from './__Src__Components__SiCtnInfo';
import { default as $__Src__Pages__Booking__Si_submission } from './__Src__Pages__Booking__Si_submission';
import { default as $__Src__Components__MultipleSelect } from './__Src__Components__MultipleSelect';
import { default as $__Src } from './__Src';
import { default as $__Src__Pages__Ship_dynamic } from './__Src__Pages__Ship_dynamic';
import { default as $__Src__Pages__Shipping_schedule } from './__Src__Pages__Shipping_schedule';
import { default as $__Src__Pages__Full_link_list } from './__Src__Pages__Full_link_list';
import { default as $__Src__Pages__Custom_appt } from './__Src__Pages__Custom_appt';
import { default as $__Src__Utils } from './__Src__Utils';
import { default as $__Src__Pages__Booking__Shipping_swiper } from './__Src__Pages__Booking__Shipping_swiper';
import { default as $__Src__Pages__Booking } from './__Src__Pages__Booking';
import { default as $__Src__Pages__Booking__Goods_info } from './__Src__Pages__Booking__Goods_info';
import { default as $__Src__Pages__Booking__DangerousModal } from './__Src__Pages__Booking__DangerousModal';
import { default as $__Src__Pages__Booking__Contact_info } from './__Src__Pages__Booking__Contact_info';
import { default as $__Src__Pages__Booking__Booking_template__TemplateDetail } from './__Src__Pages__Booking__Booking_template__TemplateDetail';
import { default as $__Src__Pages__Booking__Booking_template } from './__Src__Pages__Booking__Booking_template';
import { default as $__Src__Pages__Booking__Booking_list } from './__Src__Pages__Booking__Booking_list';
import { default as $__Src__Pages__Booking__Booking_frequently_contact } from './__Src__Pages__Booking__Booking_frequently_contact';
import { default as $__Src__Pages__Booking__Booking_frequently_contact__ContactDetail } from './__Src__Pages__Booking__Booking_frequently_contact__ContactDetail';
import { default as $__Src__Pages__Booking__Booking_detail } from './__Src__Pages__Booking__Booking_detail';
import { default as $__Src__Layout } from './__Src__Layout';
import { default as $__Src__Components__VoyageNoSelect } from './__Src__Components__VoyageNoSelect';
import { default as $__Src__Components__VesselSelect } from './__Src__Components__VesselSelect';
import { default as $__Src__Components__Header } from './__Src__Components__Header';
import { default as $__Src__Components__CompanySelect } from './__Src__Components__CompanySelect';
import { default as $__Src__Components__BookingTemplateSelect } from './__Src__Components__BookingTemplateSelect';
import { default as $__Src__ } from './__Src__';
import { default as $Shipping_schedule } from './Shipping_schedule';
import { default as $Ship_dynamic } from './Ship_dynamic';
import { default as $Contact } from './Contact';
import { default as $Modify_password } from './Modify_password';
import { default as $Utils } from './Utils';
import { default as $Src } from './Src';
import { default as $Tracking } from './Tracking';
import { default as $Home } from './Home';
import { default as $UploadResultModal } from './UploadResultModal';
import { default as $DetailModal } from './DetailModal';
import { default as $ConfirmWarehouse } from './ConfirmWarehouse';
import { default as $Full_link_list } from './Full_link_list';
import { default as $Forget_password } from './Forget_password';
import { default as $Empty_return_locations } from './Empty_return_locations';
import { default as $UploadModal } from './UploadModal';
import { default as $ReturnDetail } from './ReturnDetail';
import { default as $Custom_appt } from './Custom_appt';
import { default as $DemurrageModal } from './DemurrageModal';
import { default as $DeliveryDetail } from './DeliveryDetail';
import { default as $ContainerTracking } from './ContainerTracking';
import { default as $Calendar } from './Calendar';
import { default as $Pages } from './Pages';
import { default as $About } from './About';
import { default as $Locales } from './Locales';
import { default as $LinkModal } from './LinkModal';
import { default as $Login } from './Login';
import { default as $Header } from './Header';
import { default as $FuzzyQuery } from './FuzzyQuery';
import { default as $FormUpload } from './FormUpload';
import { default as $ } from './';

export default Object.assign(
  {},
  {
    Common: common,
    FormUpload: $FormUpload,
    FuzzyQuery: $FuzzyQuery,
    Header: $Header,
    Login: $Login,
    LinkModal: $LinkModal,
    Locales: $Locales,
    About: $About,
    Pages: $Pages,
    Calendar: $Calendar,
    ContainerTracking: $ContainerTracking,
    DeliveryDetail: $DeliveryDetail,
    DemurrageModal: $DemurrageModal,
    Custom_appt: $Custom_appt,
    ReturnDetail: $ReturnDetail,
    UploadModal: $UploadModal,
    Empty_return_locations: $Empty_return_locations,
    Forget_password: $Forget_password,
    Full_link_list: $Full_link_list,
    ConfirmWarehouse: $ConfirmWarehouse,
    DetailModal: $DetailModal,
    UploadResultModal: $UploadResultModal,
    Home: $Home,
    Tracking: $Tracking,
    Src: $Src,
    Utils: $Utils,
    Modify_password: $Modify_password,
    Shipping_schedule: $Shipping_schedule,
    Ship_dynamic: $Ship_dynamic,
    Contact: $Contact,
    __Src__Pages__Full_link_list: $__Src__Pages__Full_link_list,
    __Src__Pages__Custom_appt: $__Src__Pages__Custom_appt,
    __Src__Utils: $__Src__Utils,
    __Src__Pages__Booking__Shipping_swiper:
      $__Src__Pages__Booking__Shipping_swiper,
    __Src__Pages__Booking: $__Src__Pages__Booking,
    __Src__Pages__Booking__Goods_info: $__Src__Pages__Booking__Goods_info,
    __Src__Pages__Booking__DangerousModal:
      $__Src__Pages__Booking__DangerousModal,
    __Src__Pages__Booking__Contact_info: $__Src__Pages__Booking__Contact_info,
    __Src__Pages__Booking__Booking_template__TemplateDetail:
      $__Src__Pages__Booking__Booking_template__TemplateDetail,
    __Src__Pages__Booking__Booking_template:
      $__Src__Pages__Booking__Booking_template,
    __Src__Pages__Booking__Booking_list: $__Src__Pages__Booking__Booking_list,
    __Src__Pages__Booking__Booking_frequently_contact:
      $__Src__Pages__Booking__Booking_frequently_contact,
    __Src__Pages__Booking__Booking_frequently_contact__ContactDetail:
      $__Src__Pages__Booking__Booking_frequently_contact__ContactDetail,
    __Src__Pages__Booking__Booking_detail:
      $__Src__Pages__Booking__Booking_detail,
    __Src__Layout: $__Src__Layout,
    __Src__Components__VoyageNoSelect: $__Src__Components__VoyageNoSelect,
    __Src__Components__VesselSelect: $__Src__Components__VesselSelect,
    __Src__Components__Header: $__Src__Components__Header,
    __Src__Components__CompanySelect: $__Src__Components__CompanySelect,
    __Src__Components__BookingTemplateSelect:
      $__Src__Components__BookingTemplateSelect,
    __Src__: $__Src__,
    __Src__Pages__Shipping_schedule: $__Src__Pages__Shipping_schedule,
    __Src: $__Src,
    __Src__Pages__Ship_dynamic: $__Src__Pages__Ship_dynamic,
    __Src__Components__MultipleSelect: $__Src__Components__MultipleSelect,
    __Src__Pages__Booking__Si_submission: $__Src__Pages__Booking__Si_submission,
    __Src__Components__SiCtnInfo: $__Src__Components__SiCtnInfo,
    __Src__Pages__Home: $__Src__Pages__Home,
    __Src__Pages__News_list: $__Src__Pages__News_list,
    __Src__Pages__News_detail: $__Src__Pages__News_detail,
    __Src__Pages__Schedule: $__Src__Pages__Schedule,
    __Src__Layout__Components__Footer: $__Src__Layout__Components__Footer,
    __Src__Pages__ShipInfo: $__Src__Pages__ShipInfo,
    __Src__Pages__Tracking: $__Src__Pages__Tracking,
  },
);
