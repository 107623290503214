export default {
  Index: {
    contactEmail: '联系邮箱 :',
    contactNumber: '联系电话 :',
    address: '地址 ',
    agencyCompany: '代理公司 :',
    corporateName: '公司名称 :',
    contactUs: '联系我们',
    contactNumber1: '联系电话',
    contactEmail1: '联系邮箱',
    department: '部门',
  },
};
