export default {
  Index: {
    operation: '操作',
    volume: '体积',
    weight: '重量',
    packagingType: '包装类型',
    title: '封号 No2',
    title1: '封号 No1',
    boxType: '箱型',
    caseNo: '箱号',
    pleaseEnterTheBody1: '请输入体积',
    pleaseEnterTheWeight1: '请输入重量',
    pleaseEnterThePackage1: '请输入包装类型',
    pleaseEnterTheEnvelope2: '请输入封号 No2',
    pleaseEnterTheEnvelope3: '请输入封号 No1',
    pleaseSelectBox1: '请选择箱型',
    pleaseInputBox1: '请输入箱号',
    pleaseSelectAPackage1: '请选择包装类型单位',
  },
};
