export default {
  Index: {
    pleaseBeEarly: '请提前48小时预约否则我们无法及时安排送货',
    loginPeople: '登录人客商与提单仓库代码不一致',
    goodsOfThisTicket: '此票货物还未完成送货,还箱时间暂时无法预约',
    goodsOfThisTicket1: '此票货物还未卸船,送货时间暂无法确定,请另行预约',
    goodsOfThisTicket2: '此票货物已经预约送货,如有疑问,请和客服联系',
    goodsOfThisTicket3: '此票货物没有ABC全链路委托',
    modifyAppointment: '修改预约送货',
    newAppointment: '新增预约送货',
    theBillOfLadingNumberIs: 'TFL提单号是由4位英文字母加上6位数字组成的',
  },
};
