export default {
  Index: {
    submitBooking: '提交订舱',
    signOut: '退出',
    bookingIsIncomplete: '订舱未完成，退出后信息不做保存，是否继续订舱？',
    supportingDocuments:
      '支持 jpg, .png, .doc, .xls, .docx, .pdf,\n                .xlsx,文件，单文件小于10MB，一次最多10份',
    fileInformation: '文件信息',
    stateName: '州名',
    businessType: '业务类型',
    pleaseEnterTo: '请输入以字母"TJFH"开头的提单号',
    bookingInformation: '订舱信息',
    modifyBooking: '修改订舱信息',
    noQuery: '没有查询到船期',
    shipInspectionDate: '查船期',
    latestDeparture: '最晚离港时间',
    theEarliestDeparture: '最早离港时间',
    estimatedDeparture: 'ETD预计离港时间',
    portOfDischarge: '卸货港',
    loadingPort: '装货港',
    placeOfReceipt: '收货地',
    templateBooking: '模板订舱成功',
    templateSaving: '模板保存成功',
    pleaseSendAMessage: '请上传文件',
    modifyTemplate: '修改模板订舱成功',
    saveDraft: '保存草稿',
    newlyAdded: '新增',
    placeOfPayment: '付费地',
    modifyBooking1: '修改订舱',
    draftSave: '草稿保存成功',
    draftModification: '草稿修改成功',
    bookingTemplate: '订舱模板',
    grossWeightOfGoods: '货物毛重不得超过19000KGS',
    yes: '是',
    no: '否',
    cancel: '是否取消？',
  },
  TemplateNameModal: {
    createTemplate: '创建模板名称',
  },
  ContactModl: {
    newPayment: '新增付款人',
  },
};
