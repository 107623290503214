// import { isBrowser } from 'umi';

import { isBrowser } from '@umijs/utils/lib/ssr.js';

export default new Promise(() => {
  if (isBrowser()) {
    import(
      /* webpackChunkName: "@idx/event-path-polyfill" */ '@idx/event-path-polyfill'
    );
  }
});
