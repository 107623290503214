export default {
  En_US: {
    chineseSymbols:
      'TFL’s B/L numbers consist of 4 English letters and 6 digits.',
    chineseSymbols1: 'Please enter email’s verification code',
    chineseSymbols2: 'Email’s verification code incorrect',
    nonTelegenicUse: '非致远用户',
  },
  Zh_CN: {
    withdrawalPeriod: '离仓期计算',
    demurrageFee: '滞期费费率',
    storagePeriod: '仓储期',
    storagePeriod1: '仓储期计算',
    accordingToYour: '已按照您确认的拆箱完成日期，计算出所产生的的滞箱费。',
    confirmationTime: '确认时间',
    warehouseWork: '仓库工作时间',
    confirmCompletion: '确认完成拆箱',
    outOfStorageYard: '出堆场',
    makeAnAppointmentToReturnTheBox: '预约还箱时间',
    leaveTheStockyard: '离开堆场时间',
    addStagnantBox: '新增滞箱费',
    added: '已添加',
    currentState: '当前状态',
    unableToCountWith:
      '无法与TFL数据库匹配的仓库信息，将作为新仓库在TFL数据库中添加',
    andDatabase: '与TFL数据库匹配一致的仓库信息将自动导入TFL系统',
    downloadFailed: '下载失败文件',
    pleaseMatch: '请匹配上传成功的数据，部分数据上传失败',
    confirmExpenses: '确认费用成功',
    operationSuccessful: '操作成功',
    addToNew: '添加至新仓',
    confirmMatch: '确认匹配',
    matchingWarehouse: '匹配仓库地址',
    demurrageCharge: '滞箱费费率',
    withdrawalPeriod1: '离仓期',
    overdueDays: '超期天数',
    freePeriod: '免费期',
    makeAnAppointmentForDelivery: '预约送货时间',
    enterTheStorageYard: '进入堆场时间',
    confirmExpenses1: '确认费用',
    usAppointment: '美国预约送货',
    scopeOfServices: '服务范围',
    productIntroduction: '产品介绍',
    weekday: '工作日',
    naturalDay: '自然日',
    newDemurrage: '新增滞期费',
    ifItNeedsToBeModified: '如需修改预约时间，请联系致远客服。',
    vehicleDispatched: '车辆已派',
    leaveTheWarehouse: '离开仓库',
    arriveAtWarehouse: '到达仓库',
    enterTheStorageYard1: '进堆场',
    exitTheDock: '出码头',
    shipUnloading: '卸船',
    canceled: '已取消',
    completed: '已完成',
    booked: '已预约',
    draft: '草稿',
    defaultWarehouse: '默认仓库地址',
    paymentVoucher: '支付凭证',
    payment1: '支付',
    confirmTheStagnationBox: '确认滞箱费',
    appointmentSuccessful: '预约成功',
    appointmentResults: '预约结果',
    returnTime: '还箱时间',
    pleaseEnterTheWithdrawal: '请输入提单号、箱号',
    newAppointment: '新增预约',
    advanceOfContainerDetentionFee: '滞箱费预算',
    advanceOfContainerDetentionFee1: '滞箱费预算合计',
    detailsOfDemurrageCharges: '滞箱费详情',
    confirmationOfDemurrage: '确认滞期费',
    demurrageAdvance: '滞期费预算',
    demurrageAdvance1: '滞期费预算合计',
    demurrageDetails: '滞期费详情',
    accordingToYourExpectation:
      '按照您预约的送货日期，将有滞期费产生，请先支付滞期费并上传支付凭证。',
    return1: '返回',
    viewDemurrage: '查看滞期费',
    confirm7: '确认',
    deliveryTime: '送货时间',
    pleaseBeEarly: '请提前48小时预约，否则我们无法及时安排还箱。',
    pleaseBeEarly1: '请提前48小时预约，否则我们无法及时安排送货。',
    earliestReturn: '最早还箱日期',
    earliestDelivery: '最早送货日期',
    modifyAppointment: '修改预约还箱',
    modifyAppointment1: '修改预约送货',
    newAppointment1: '新增预约还箱',
    newAppointment2: '新增预约送货',
    unconfirmed: '未确认',
    confirmed: '已确认',
    uploadPayment: '上传支付凭证',
    modify2: '修改',
    makeAnAppointmentToReturnTheBox1: '预约还箱',
    makeAnAppointmentForDelivery1: '预约送货',
    makeAnAppointmentForDelivery2: '预约送货/还箱列表',
    addReturnBox: '新增还箱',
    addDelivery: '新增送货',
    demurrageCharge1: '滞箱费确认',
    demurrageCharge2: '滞箱费',
    containerMovement: '集装箱动态',
    completionTime: '完成时间',
    timeOfAppointment: '预约时间',
    demurrage7: '滞期费',
    appointmentStatus: '预约状态',
    mailbox: '邮箱',
    telephone: '电话',
    preservation: '保存',
    delete: '删除',
    cancel1: '取消',
    edit: '编辑',
    postalCode: '邮编',
    warehouseInformation: '仓库信息',
    details1: '详情信息',
    newWarehouse: '新增仓库信息',
    savedSuccessfully: '保存成功',
    atLeastKeep: '至少保留一个仓库',
    formUpload: '表格上传成功！',
    causeOfError: '错误原因',
    thatSOk: '行',
    pleaseCheckAnd: '请核对并修改以下信息后，再重新上传',
    uploadSuccessful: '上传成功',
    uploadResults: '上传结果',
    filesOnly: '文件仅限excel格式，且文件大小限制在5M及以内',
    confirmUpload: '确认上传',
    confirmWarehouse: '确认仓库-仓库清单上传',
    confirmCancellation: '确认取消',
    cancelTheDelegation: '取消委托成功',
    newlyAdded: '新增',
    addAtMost: '最多新增五个',
    minimumRetention: '最少保留一个',
    pleaseEnterTheState: '请输入州名',
    stateName: '州名',
    pleaseSelect: '请选择',
    pleaseSelectACity: '请选择城市名',
    cityName: '城市名',
    newDelegation: '新建委托成功',
    cancelTheDelegation1: '取消委托',
    confirmWarehouse1: '确认仓库',
    importTemplate: '导入模板下载',
    newDelegation1: '新增委托',
    reset: '重置',
    screen: '筛选',
    pleaseEnterTheWarehouse: '请输入仓库名称',
    pleaseInputBox: '请输入箱号',
    theBillOfLadingNumberIs: 'TFL提单号是由4位英文字母加上6位数字组成的。',
    pleaseEnterTheWithdrawal1: '请输入提单号',
    viewAll: '查看全部',
    putAwayAll: '收起全部',
    fullLinkColumn: 'ABC全链路列表',
    editTime: '编辑时间',
    creationTime: '创建时间',
    contactEmail: '联系邮箱',
    contactNumber: '联系电话',
    state1: '状态',
    warehouseAddress: '仓库地址',
    warehouseName: '仓库名称',
    caseNo: '箱号',
    billOfLadingNo: '提单号',
    nextStep: '下一步',
    getValidation: '获取验证码',
    pleaseEnterEmail: '请输入邮箱验证码',
    pleaseEnterTheVerification: '请输入验证码',
    bindMailbox: '绑定邮箱',
    userName: '用户名',
    retrievePassword: '找回密码',
    verificationCodeSending: '验证码发送成功，请进入邮箱查询',
    thePageWillBeDisplayedIn: '页面将在5秒后跳转',
    logInAgain: '重新登录',
    pleaseGoBackToTheFirst: '请回到首页',
    resetPassword: '重置密码成功',
    complete1: '完成',
    resetPassword1: '重置密码',
    authentication: '身份验证',
    confirmModification: '确定修改',
    confirmNewSecret: '确认新密码',
    newPassword: '新密码',
    currentPassword: '当前密码',
    passwordFormat: '密码格式：英文字母、数字组合，长度最少为6位',
    newPasswordBook: '新密码录入不一致',
    unknownException: '未知异常',
    queryWarehouse: '查询仓库列表异常',
    pleaseContactTheCustomer: '请联系客服维护邮箱',
    cancelWarehouse: '取消仓库委托异常',
    warehouseConfirmation: '仓库确认异常',
    modifyWarehouse: '修改仓库明细异常',
    unsupported: '不支持的客户端',
    unsupported1: '不支持的授权方式',
    parameterCannot: '参数不能为空',
    mailboxVerification: '邮箱验证码错误',
    verificationCodeError: '验证码错误',
    userNameOr: '用户名或密码错误',
    wrongUserName: '用户名错误',
    theOriginalPasswordIsWrong: '原密码错误',
    newPasswordAnd: '新密码与原密码一致',
    passwordFormat1: '密码格式不合符规定',
    loginPassed: '登录已过期，请重新登录',
    forgetPassword: '忘记密码',
    availableHere:
      '此处提供的空箱返还情况仅供参考。致远航运有限公司不保证上述信息的即时准确性。请及时登录码头网站查询还箱最新动态。',
    unableToQuery: '查询不到此提单号信息，请重新输入',
    emptyBoxReturn: '空箱返还',
    cargoDeparture: '货物出港',
    cargoUnloading: '货物卸船',
    vesselArrival: '船舶到港',
    shipSailing: '船舶开航',
    shipmentOfGoods: '货物装船',
    cargoArrival: '货物进港',
    pickUpEmptyContainers: '提取空箱',
    boxVolumeBoxType: '箱量箱型',
    container1: '集装箱',
    portOfDischarge: '卸货港',
    loadingPort: '装货港',
    destination: '目的地',
    placeOfReceipt: '收货地',
    voyageNumber: '航次',
    shipName: '船名',
    basicInformation: '基础信息',
    freePeriodAnd: '免用期和超期用箱费基本费率',
    contactAddress: '联系地址',
    contacts: '联系人',
    meetTheVast: '满足广大跨境电商卖家日益增长的跨境履约交付需求',
    endToEndNumber: '端到端数字化物流',
    highCertainty: '高确定性自营产品',
    endToEndShoes: '端到端履约交付通道',
    committedToStructure: '致力于构建\tB2B跨境电商物流',
    allElementsFrom: '全要素自营',
    improveSupply: '提升供应链交付效率',
    cabinAndCabinetProtection: '保舱保柜',
    pleaseEnterTheWithdrawal2: '请输入提单号或柜号',
    search: '搜索',
    download1: '下载',
    operation1: '操作',
    uploadTime: '上传时间',
    englishDocuments: '英文文件名称',
    chineseDocuments: '中文文件名称',
    seeMore: '查看更多',
    aboutUs: '关于我们',
  },
};
