import I18N from '@/utils/I18N';
import useIsCn from '@/hooks/useIsCn';

const isCn = useIsCn();

export const errorCodeMessage: any = () => ({
  101001: isCn
    ? I18N.Locales.Zh_CN.parameterCannot
    : 'Parameters can not be null',
  104002: isCn
    ? I18N.Locales.Zh_CN.unsupported1
    : 'authorization methods unsupported',
  104003: isCn ? I18N.Locales.Zh_CN.unsupported : 'Clients Unsupported',
  101004: isCn ? I18N.Pages.Config.passwordCannot : 'Password can not be null',
  101005: isCn ? I18N.Pages.Config.userNameIsNot : 'User name can not be null',
  103001: isCn
    ? I18N.Locales.Zh_CN.userNameOr
    : 'User name or password incorrect',
  103002: isCn
    ? I18N.Locales.Zh_CN.theOriginalPasswordIsWrong
    : 'Password invalid',
  103003: isCn ? I18N.Locales.En_US.nonTelegenicUse : 'non-users of TFL',
  103004: isCn
    ? I18N.Locales.Zh_CN.newPasswordAnd
    : 'New password is same as the previous one.',
  103005: isCn
    ? I18N.Locales.Zh_CN.modifyWarehouse
    : 'Warehouse modification error',
  103006: isCn
    ? I18N.Locales.Zh_CN.warehouseConfirmation
    : 'Warehouse confirmation error',
  103007: isCn
    ? I18N.Locales.Zh_CN.cancelWarehouse
    : 'Warehouse cancellation error',
  103017: isCn
    ? I18N.Locales.Zh_CN.pleaseContactTheCustomer
    : 'Please contact customer service for support',
  103018: isCn
    ? I18N.Locales.Zh_CN.passwordFormat1
    : 'The password format does not match the requirements',
  103008: isCn
    ? I18N.Locales.Zh_CN.queryWarehouse
    : 'Please contact customer service for support',
  103009: isCn
    ? I18N.Locales.Zh_CN.verificationCodeError
    : 'Verification code incorrect',
  103010: isCn
    ? I18N.Locales.Zh_CN.mailboxVerification
    : I18N.Locales.En_US.chineseSymbols2,
  103011: isCn ? I18N.Pages.Config.billOfLadingNo1 : 'B/L numbers do not match',
  103012: isCn
    ? I18N.Pages.Config.billOfLadingNoWeight
    : 'This B/L number is a duplicate',
  103013: isCn ? I18N.Pages.Config.outOfTime : 'Exceeded ATA',
  103014: isCn
    ? I18N.Pages.Config.billOfLadingTransportation
    : 'ABC End-2-End Solutions were not arranged for this shipment. Please contact customer service',
  103015: isCn
    ? I18N.Pages.Config.customerIsNot
    : 'The customer type is not shipper',
  103016: isCn ? I18N.Pages.Config.cityDropDown : 'City drop-down menu error',
  103019: isCn
    ? I18N.Pages.Config.caseNoIsNotIn
    : 'This container is not under this B/L NO',
  103020: isCn
    ? I18N.Pages.Config.billOfLadingEntrustment
    : 'B/L order has been cancelled.',
  9001: isCn ? I18N.Locales.Zh_CN.unknownException : 'Unknown error',
});
