export default {
  Index: {
    newlyAdded: '新增',
    reset: '重置',
    screen: '筛选',
    creationTime: '创建时间',
    delete: '删除',
    determine: '确定',
    areYouSure: '是否确定删除？',
    edit: '编辑',
    operation: '操作',
  },
};
