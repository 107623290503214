export default {
  Index: {
    container: '集装箱:',
    boxVolumeBoxType: '箱量箱型:',
    destination: '目的地:',
    portOfDischarge: '卸货港:',
    loadingPort: '装货港:',
    placeOfReceipt: '收货地:',
    billOfLadingNo1: '提单号:',
  },
};
