export default {
  Index: {
    createANewMold: '创建新模板',
    pleaseEnterTheModule: '请输入模板名称',
    templateName: '模板名称',
    bookingTemplate: '订舱模板列表',
    lastUsed: '上次使用时间',
    lastRepair: '上一次修改',
  },
};
