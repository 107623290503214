// import { isBrowser } from 'umi';

import { isBrowser } from '@umijs/utils/lib/ssr.js';

export default new Promise((resolve) => {
  if (isBrowser()) {
    import(
      /* webpackChunkName: "@dzg/common-utils-request" */ '@dzg/common-utils/lib/request'
    ).then((utils) => {
      resolve(utils.getMessageWidthTraceID);
    });
  } else {
    resolve(() => {});
  }
});
