export default {
  Index: {
    search: '搜索',
    pleaseEnterTheShip: '请输入船名',
    shipName: '船名:',
    shipInformation: '船舶信息',
    zhiyuanService: '致远服务',
    homePage: '首页',
    noCompliance: '没有符合你搜索的船只。请再试一次',
    shipRegistry: '船籍',
    classificationSociety: '船级社',
    callSign: '呼号',
    number: 'IMO编号',
    deckBoxQuantity: '甲板箱量',
    dateOfConstruction: '建造日期',
    shipName1: '船名',
    shipSClassification: '船级',
  },
};
