import type { Effect } from 'dva';
import { login, loginOut } from '@/services/login';
import { history } from 'umi';
import { errorCodeMessage } from '@/pages/config';

export interface ILogin {
  loginModalShow: boolean;
  loginErrorTip: string;
}

export interface BaseModel {
  namespace: string;
  state: ILogin;
  effects: {
    login: Effect;
    loginOut: Effect;
  };
  reducers: any;
}

const Model: BaseModel = {
  namespace: 'login',
  state: {
    loginModalShow: false,
    loginErrorTip: '',
  },
  effects: {
    *login({ payload, callback }: any, { call, put }: any): any {
      const response = yield call(login, payload);
      if (response) {
        if (response.errorCode === 0) {
          // history.push('/home');
          localStorage.setItem('userInfo', JSON.stringify(response.result));
          localStorage.setItem('token', response.result?.token ?? '');
          yield put({
            type: 'applyLoginModalShow',
            payload: false,
          });
          yield put({
            type: 'applyLoginErrorTip',
            payload: '',
          });
          callback && callback();
        } else {
          yield put({
            type: 'applyLoginErrorTip',
            payload: errorCodeMessage()[response.errorCode],
          });
        }
      }
    },
    *loginOut({ payload }: any, { call, put }: any): any {
      const response = yield call(loginOut, payload);
      if (response && response.errorCode === 0) {
        window.location.href = `${window.location.origin}`;
        localStorage.removeItem('userInfo');
        localStorage.removeItem('token');
      }
    },
  },
  reducers: {
    applyLoginModalShow(state: any, { payload }: any) {
      return {
        ...state,
        loginModalShow: payload,
      };
    },
    applyLoginErrorTip(state: any, { payload }: any) {
      return {
        ...state,
        loginErrorTip: payload,
      };
    },
  },
};

export default Model;
