export default {
  Index: {
    loadMore: '加载更多',
    newsCenter: '新闻中心',
    homePage: '首页',
    noMore: '没有更多数据了',
    seeMore: '查看更多',
    media: '媒体',
    customerAnnouncement: '客户通告',
    journalism: '新闻',
  },
};
