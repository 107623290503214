export default {
  Menu: {
    commonContact: '常用联系人',
    bookingTemplate: '订舱模板',
    orderList: '订单列表',
    newBooking: '新建订舱',
    shippingSchedule: '船期表',
    logisticsTracking: '物流跟踪',
    draftBillOfLading: '提单草稿列表',
  },
};
