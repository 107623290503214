export default {
  Const: {
    emptyBoxReturn: '空箱返还时间',
    heavyContainerDeparture: '重箱出港时间',
    heavyContainerUnloading: '重箱卸船时间',
    shipArrival: '船舶抵港时间',
    shipDeparture: '船舶离港时间',
    heavyContainerArrival: '重箱进港时间',
    pickUpEmptyContainers: '提取空箱时间',
  },
  Validform: {
    mailboxFormat: '邮箱格式不正确，如有多个请用英文逗号隔开',
  },
};
