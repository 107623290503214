export default {
  Index: {
    contactUs: '联系我们',
    author: '作者：',
    source: '来源：',
    time: '时间：',
    newsDetail: '新闻详情',
    enclosure: '附件：',
    releaseTime: '发布时间：',
  },
};
