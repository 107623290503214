export default {
  Index: {
    selectAll: '选择全部SI状态',
    selectAll1: '选择全部订舱状态',
    pleaseSelect: '请选择',
    refuse: 'SI拒绝',
    cancel: 'SI取消',
    confirm: 'SI确认',
    submit: 'SI提交',
    mediumProcessing: 'SI处理中/等待',
    bookingChange: '订舱更改请求',
    bookingRefusal: '订舱拒绝',
    cancellationOfBooking: '订舱取消',
    bookingConfirmation: '订舱确认',
    bookingSubmission: '订舱提交',
    bookingWaiting: '订舱等待',
  },
};
