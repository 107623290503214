// import { loading } from '@dzg/common-utils';
import commonUtils from '@/utils/dynamic/common-utils';
import { request } from 'umi';

// 登录
export const login = async (data: { username: string; password: string }) => {
  let { loading } = await commonUtils;
  const { username, password } = data;
  loading.start();
  return request(
    `/transfar/oauth/token?grant_type=password&username=${username}&password=${password}&scope=all&client_id=ZY_PORTAL_BACKGROUND&client_secret=ZY_PORTAL_BACKGROUND`,
    {
      method: 'POST',
    },
  ).finally(() => {
    loading.destroy();
  });
};

// 退出登录
export const loginOut = async (data: { token: string }) => {
  return request(`/transfar/portalUnAuth/loginout?token=${data.token}`, {
    method: 'GET',
  });
};
