export default {
  LinerSteps: {
    arrival: '到港',
    departure: '离港',
  },
  ShippingItem: {
    expandDetails: '展开详情',
    stowDetails: '收起详情',
    choice: '选择',
    deselect: '取消选择',
    arrivalDeadline: '进港截止日期',
  },
};
