export default {
  umi__Core: {
    PluginRegister: {
      work: 'C:/Users/xudeyi/Desktop/工作/transfar-website/src/.umi/plugin-locale/runtime.tsx',
      work1:
        'C:/Users/xudeyi/Desktop/工作/transfar-website/src/.umi/plugin-dva/runtime.tsx',
      work2: 'C:/Users/xudeyi/Desktop/工作/transfar-website/src/app.tsx',
    },
  },
  umi__Plugin_dva: {
    Connect: {
      work: 'C:/Users/xudeyi/Desktop/工作/transfar-website/src/models/login',
    },
  },
  umi__Plugin_helmet: {
    Exports: {
      work: 'C:/Users/xudeyi/Desktop/工作/transfar-website/node_modules/react-helmet',
    },
  },
  umi__Plugin_locale: {
    LocaleExports: {
      useThisMeeting:
        'Using this API will cause automatic refresh when switching languages, please use useIntl or injectIntl.\n\n使用此 api 会造成切换语言的时候无法自动刷新，请使用 useIntl 或 injectIntl。\n\nhttp://j.mp/37Fkd5Q\n      ',
      work: 'C:/Users/xudeyi/Desktop/工作/transfar-website/node_modules/react-intl',
    },
    SelectLang: {
      chineseSymbols: '🌐',
      language: '語言',
      chineseSymbols1: '🇭🇰',
      traditionalChinese: '繁體中文',
      language1: '语言',
      chineseSymbols2: '🇨🇳',
      simplifiedChinese: '简体中文',
      chineseSymbols3: 'Ngôn ngữ',
      chineseSymbols4: '🇻🇳',
      chineseSymbols5: 'Tiếng Việt',
      chineseSymbols6: 'Мова',
      chineseSymbols7: '🇺🇰',
      chineseSymbols8: 'Українська',
      chineseSymbols9: '🇹🇷',
      chineseSymbols10: 'ภาษา',
      chineseSymbols11: '🇹🇭',
      chineseSymbols12: 'ไทย',
      chineseSymbols13: 'மொழி',
      chineseSymbols14: '🇮🇳',
      chineseSymbols15: 'தமிழ்',
      chineseSymbols16: '🇸🇪',
      chineseSymbols17: '🇸🇱',
      sC: 'Slovenščina',
      chineseSymbols18: 'Језик',
      chineseSymbols19: '🇸🇷',
      chineseSymbols20: 'српски језик',
      chineseSymbols21: '🇸🇰',
      c22: 'Slovenčina',
      chineseSymbols22: 'язык',
      chineseSymbols23: '🇷🇺',
      chineseSymbols24: 'русский',
      chineseSymbols25: '🇷🇴',
      a: 'Română',
      chineseSymbols26: '🇵🇹',
      chineseSymbols27: '🇧🇷',
      chineseSymbols28: '🇳🇱',
      chineseSymbols29: '🇧🇪',
      chineseSymbols30: 'भाषा',
      chineseSymbols31: '🇳🇵',
      chineseSymbols32: 'नेपाली',
      chineseSymbols33: '🇳🇴',
      chineseSymbols34: '🇲🇾',
      chineseSymbols35: 'بهاس ملايو‎',
      chineseSymbols36: 'Хэл',
      chineseSymbols37: '🇲🇳',
      chineseSymbols38: 'Монгол хэл',
      chineseSymbols39: 'Јазик',
      chineseSymbols40: '🇲🇰',
      chineseSymbols41: 'македонски јазик',
      chineseSymbols42: '🇱🇮',
      s2: 'Latviešu valoda',
      chineseSymbols43: '언어',
      chineseSymbols44: '🇰🇷',
      chineseSymbols45: '한국어',
      chineseSymbols46: 'ಭಾಷೆ',
      chineseSymbols47: 'ಕನ್ನಡ',
      chineseSymbols48: '🇮🇶',
      chineseSymbols49: 'کوردی',
      speech: '言語',
      chineseSymbols50: '🇯🇵',
      japanese: '日本語',
      chineseSymbols51: '🇮🇸',
      chineseSymbols52: '🇮🇹',
      chineseSymbols53: '🇮🇩',
      chineseSymbols54: 'Լեզու',
      chineseSymbols55: '🇦🇲',
      chineseSymbols56: 'Հայերեն',
      chineseSymbols57: '🇭🇺',
      chineseSymbols58: '🇭🇷',
      chineseSymbols59: 'भाषा: हिन्दी',
      chineseSymbols60: 'हिन्दी, हिंदी',
      chineseSymbols61: 'שפה',
      chineseSymbols62: '🇮🇱',
      chineseSymbols63: 'עברית',
      chineseSymbols64: '🇮🇪',
      chineseSymbols65: '🇫🇷',
      chineseSymbols66: '🇫🇮',
      chineseSymbols67: 'زبان',
      chineseSymbols68: '🇮🇷',
      chineseSymbols69: 'فارسی',
      chineseSymbols70: '🇪🇪',
      chineseSymbols71: '🇪🇸',
      chineseSymbols72: '🇺🇸',
      chineseSymbols73: '🇬🇧',
      chineseSymbols74: 'Γλώσσα',
      chineseSymbols75: '🇬🇷',
      chineseSymbols76: 'Ελληνικά',
      chineseSymbols77: '🇩🇪',
      chineseSymbols78: '🇩🇰',
      chineseSymbols79: '🇨🇿',
      cs: 'Čeština',
      chineseSymbols80: '🇨🇦',
      chineseSymbols81: 'език',
      chineseSymbols82: '🇧🇬',
      chineseSymbols83: 'Български език',
      chineseSymbols84: '🇦🇿',
      chineseSymbols85: 'Azərbaycan dili',
      chineseSymbols86: 'لغة',
      chineseSymbols87: '🇪🇬',
      chineseSymbols88: 'العربية',
    },
  },
  umi__Plugin_request: {
    Request: {
      work: 'C:/Users/xudeyi/Desktop/工作/transfar-website/node_modules/@ahooksjs/use-request/lib/types',
      work1:
        'C:/Users/xudeyi/Desktop/工作/transfar-website/node_modules/umi-request',
    },
  },
  umi: {
    Umi: {
      zhiyuanShipping: '致远航运',
    },
  },
};
